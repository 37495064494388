import Label from '../labels/Label'

import './LabelAndTextInput.css'

interface LabelAndTextInputProps {
	id: string
	text: string
	placeholder?: string
	value?: string
	required?: Object
	readOnly?: Object
	onChange: (value: string) => void
	style?: React.CSSProperties
}

function LabelAndTextInput(props: LabelAndTextInputProps) {
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		props.onChange(e.target.value)
	}

	return (
		<div style={props.style} className='LabelAndTextInput'>
			<Label htmlFor={props.id}>{props.text}</Label>
			<input
				type='text'
				placeholder={props.placeholder}
				id={props.id}
				value={props.value}
				name={props.id}
				{...props.required}
				{...props.readOnly}
				onChange={handleChange}
			/>
		</div>
	)
}

export default LabelAndTextInput
