import React from 'react'

import './Pictogram.css'

interface PictogramProps {
	id: string
	src: string
	onChange?: (value: string) => void
	children?: React.ReactNode
	style?: React.CSSProperties
	className?: string
}

function Pictogram(props: PictogramProps) {
	const handleChange = () => {
		if (props.onChange) {
			props.onChange(props.id)
		}
	}

	return (
		<div
			className={props.className ? props.className : 'picto0'}
			onClick={handleChange}
			style={props.style}
		>
			<img src={props.src} alt={props.id} />
			<p>{props.children}</p>
		</div>
	)
}

export default Pictogram
