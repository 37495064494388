import './IconAndText.css'

interface IconAndTextProps {
	icon: string
	text: string
	invertIcon?: boolean
	className?: string
}

function IconAndText(props: IconAndTextProps) {
	if (props.invertIcon) {
		return (
			<div
				className={props.className ? props.className + ' iconAndText invert' : 'iconAndText invert'}
			>
				{props.text}
				<img src={props.icon} alt={props.text} />
			</div>
		)
	}

	return (
		<div className={props.className ? props.className + ' iconAndText' : 'iconAndText'}>
			<img src={props.icon} alt={props.text} />
			{props.text}
		</div>
	)
}

export default IconAndText
