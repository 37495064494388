import { createSlice } from '@reduxjs/toolkit'

type FundingState = {
  contributionAmount: string
  loanAmount: string
  loanDuration: string
  interestRate: string
  hasNotary: string
}

const initialState: FundingState = {
  contributionAmount: '',
  loanAmount: '',
  loanDuration: '',
  interestRate: '',
  hasNotary: '',
}

export const fundingSlice = createSlice({
  name: 'Funding',
  initialState,
  reducers: {
    setContributionAmount: (state, action) => {
      state.contributionAmount = action.payload
    },
    setLoanAmount: (state, action) => {
      state.loanAmount = action.payload
    },
    setLoanDuration: (state, action) => {
      state.loanDuration = action.payload
    },
    setInterestRate: (state, action) => {
      state.interestRate = action.payload
    },
    setHasNotary: (state, action) => {
      state.hasNotary = action.payload
    },
  },
})

export const {
  setContributionAmount,
  setLoanAmount,
  setLoanDuration,
  setInterestRate,
  setHasNotary,
} = fundingSlice.actions

export default fundingSlice.reducer
