import Label from '../labels/Label'
import RadioAndLabel from './RadioAndLabel'

import './RadioGroupAndLabel.css'

interface RadioGroupAndLabelProps {
  id: string
  text: string
  options: { [key: string]: string }
  required?: Object
  onChange: (value: string) => void
  defaultChecked?: string
  style?: React.CSSProperties
}

function RadioGroupAndLabel(props: RadioGroupAndLabelProps) {
  const handleChange = (data: string) => {
    props.onChange(data)
  }

  return (
    <div style={props.style} className='RadioGroupAndLabel'>
      <Label htmlFor={props.id}>{props.text}</Label>
      {Object.keys(props.options).map(key => (
        <RadioAndLabel
          id={key}
          key={key}
          text={props.options[key]}
          name={props.id}
          defaultChecked={props.defaultChecked}
          {...props.required}
          onChange={handleChange}
        />
      ))}
    </div>
  )
}

export default RadioGroupAndLabel
