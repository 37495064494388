import React from 'react'

import './Button.css'

interface ButtonProps {
	children: React.ReactNode
	onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

function Button(props: ButtonProps) {
	return <button onClick={props.onClick}>{props.children}</button>
}

export default Button
