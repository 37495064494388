import React from 'react'

import LabelAndTextInput from '../inputs/LabelAndTextInput'
import RadioGroupAndLabel from '../radio/RadioGroupAndLabel'
import Title from '../title/Title'
import Pictogram from '@components/form/pictogram/Pictogram'
import cash from '@assets/form/comptant.svg'
import loan from '@assets/form/pret.svg'
import {
	setContributionAmount,
	setHasNotary,
	setInterestRate,
	setLoanAmount,
	setLoanDuration,
} from '@components/form/sections/fundingSlice'
import { useAppDispatch, useAppSelector } from '@/hooks'
import type { RootState } from '@/store'

import './Section.css'

interface FundingProps {
	fundingType: string
	onSetFundingType: (value: string) => void
}

function Funding(props: FundingProps) {
	return (
		<div className='section'>
			<Title>Votre financement</Title>
			<div className='pictograms'>
				<Pictogram
					className={'picto1' + (props.fundingType === 'loan' ? ' active' : '')}
					src={loan}
					id='loan'
					onChange={() => props.onSetFundingType('loan')}
				>
					Crédit immobilier
				</Pictogram>
				<Pictogram
					className={'picto1' + (props.fundingType === 'cash' ? ' active' : '')}
					src={cash}
					id='cash'
					onChange={() => props.onSetFundingType('cash')}
				>
					Comptant
				</Pictogram>
			</div>
			{props.fundingType === 'loan' && <Loan />}
			{props.fundingType === 'cash' && <Cash />}
		</div>
	)
}

function Loan() {
	const dispatch = useAppDispatch()

	const getContributionAmount = useAppSelector(
		(state: RootState) => state.funding.contributionAmount,
	)
	const onSetContributionAmount = (value: string) => {
		dispatch(setContributionAmount(value))
	}

	const getLoanAmount = useAppSelector((state: RootState) => state.funding.loanAmount)
	const onSetLoanAmount = (value: string) => {
		dispatch(setLoanAmount(value))
	}

	const getLoanDuration = useAppSelector((state: RootState) => state.funding.loanDuration)
	const onSetLoanDuration = (value: string) => {
		dispatch(setLoanDuration(value))
	}

	const getInterestRate = useAppSelector((state: RootState) => state.funding.interestRate)
	const onSetInterestRate = (value: string) => {
		dispatch(setInterestRate(value))
	}

	const getHasNotary = useAppSelector((state: RootState) => state.funding.hasNotary)
	const onSetHasNotary = (value: string) => {
		dispatch(setHasNotary(value))
	}

	return (
		<div>
			<div className='formContent'>
				<div className='fieldLine'>
					<LabelAndTextInput
						id='contributionAmount'
						text="Montant de l'apport"
						placeholder='50 000 €'
						value={getContributionAmount}
						required={{ required: true }}
						onChange={onSetContributionAmount}
					/>
					<LabelAndTextInput
						id='loanAmount'
						text='Montant du prêt'
						placeholder='450 000 €'
						value={getLoanAmount}
						required={{ required: true }}
						onChange={onSetLoanAmount}
					/>
				</div>
				<div className='fieldLine'>
					<LabelAndTextInput
						id='loanDuration'
						text='Durée du prêt'
						placeholder='20 ans'
						value={getLoanDuration}
						required={{ required: true }}
						onChange={onSetLoanDuration}
					/>
					<LabelAndTextInput
						id='interestRate'
						text='Taux d’intérêt'
						placeholder='1,5 %'
						value={getInterestRate}
						required={{ required: true }}
						onChange={onSetInterestRate}
					/>
				</div>
				<div className='fieldLine'>
					<RadioGroupAndLabel
						id='hasNotary'
						text='Avez-vous un notaire ?'
						options={{ no: 'Non', yes: 'Oui' }}
						defaultChecked={getHasNotary}
						required={{ required: true }}
						onChange={onSetHasNotary}
					/>
				</div>
			</div>
		</div>
	)
}

function Cash() {
	const dispatch = useAppDispatch()

	const getHasNotary = useAppSelector((state: RootState) => state.funding.hasNotary)
	const onSetHasNotary = (value: string) => {
		dispatch(setHasNotary(value))
	}

	return (
		<div>
			<div className='formContent'>
				<div className='fieldLine'>
					<RadioGroupAndLabel
						id='hasNotary'
						text='Avez-vous un notaire ?'
						options={{ no: 'Non', yes: 'Oui' }}
						defaultChecked={getHasNotary}
						required={{ required: true }}
						onChange={onSetHasNotary}
					/>
				</div>
			</div>
		</div>
	)
}

export default Funding
