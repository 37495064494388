import React from 'react'

import LabelAndTextInput from '../inputs/LabelAndTextInput'
import Title from '../title/Title'
import Pictogram from '@components/form/pictogram/Pictogram'
import house from '@assets/form/house.svg'
import room4 from '@assets/form/4pièces.svg'

import './Section.css'

function Section1() {
	return (
		<div className='section1'>
			<Title>Récapitulatif de votre bien</Title>
			<div className='pictograms'>
				<Pictogram className='picto0' src={house} id='house'>
					Maison
				</Pictogram>
				<Pictogram className='picto0' src={room4} id='room4'>
					4 pièces
				</Pictogram>
			</div>
			<div className='formContent'>
				<div className='fieldLine'>
					<LabelAndTextInput
						id='residenceType'
						text='Type de résidence'
						placeholder='Neuf accession libre'
						required={{ required: false }}
						readOnly={{ readOnly: true }}
						onChange={value => console.log('redidenceType: ' + value)}
					/>
					<LabelAndTextInput
						id='id'
						text='Numéro de lot'
						placeholder='no 008'
						required={{ required: false }}
						readOnly={{ readOnly: true }}
						onChange={value => console.log('id: ' + value)}
					/>
					<LabelAndTextInput
						id='area'
						text='Superficie'
						placeholder='100 m²'
						required={{ required: false }}
						readOnly={{ readOnly: true }}
						onChange={value => console.log('area: ' + value)}
					/>
				</div>
				<div className='fieldLine'>
					<LabelAndTextInput
						id='parking'
						text='Parking'
						placeholder='1 place extérieure'
						required={{ required: false }}
						readOnly={{ readOnly: true }}
						onChange={value => console.log('parking: ' + value)}
					/>
					<LabelAndTextInput
						id='usage'
						text='Usage du bien'
						placeholder='Résidence principale'
						required={{ required: false }}
						readOnly={{ readOnly: true }}
						onChange={value => console.log('usage: ' + value)}
					/>
				</div>
				<div className='fieldLine'>
					<LabelAndTextInput
						id='vat'
						text='Taux de TVA'
						placeholder='20 %'
						required={{ required: false }}
						readOnly={{ readOnly: true }}
						onChange={value => console.log('vat: ' + value)}
					/>
					<LabelAndTextInput
						id='price'
						text='Prix'
						placeholder='450 000 €'
						required={{ required: false }}
						readOnly={{ readOnly: true }}
						onChange={value => console.log('price: ' + value)}
					/>
				</div>
			</div>
		</div>
	)
}

export default Section1
