import { createSlice } from '@reduxjs/toolkit'

type TermsState = {
	termsAndConditions: boolean
}

const initialState: TermsState = {
	termsAndConditions: false,
}

export const termsSlice = createSlice({
	name: 'Terms',
	initialState,
	reducers: {
		setTermsAndConditions: (state, action) => {
			state.termsAndConditions = action.payload
		},
	},
})

export const { setTermsAndConditions } = termsSlice.actions

export default termsSlice.reducer
