import { configureStore } from '@reduxjs/toolkit'

import personReducer from './components/form/sections/personSlice'
import corporationReducer from './components/form/sections/corporationSlice'
import fundingReducer from './components/form/sections/fundingSlice'
import notaryReducer from './components/form/sections/notarySlice'
import termsReducer from './components/form/sections/termsSlice'

export const store = configureStore({
	reducer: {
		person: personReducer,
		corporation: corporationReducer,
		funding: fundingReducer,
		notary: notaryReducer,
		terms: termsReducer,
	},
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
