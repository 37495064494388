import React, { ChangeEvent, DragEvent, useState } from 'react'

import './File.css'

interface FileProps {
	id: string
	src: string
	onChange?: (value: string) => void
	children?: React.ReactNode
	style?: React.CSSProperties
}

function File(props: FileProps) {
	const [dragActive, setDragActive] = useState(false)

	const handleDrag = function (e: DragEvent<HTMLDivElement>) {
		e.preventDefault()
		e.stopPropagation()
		if (e.type === 'dragenter' || e.type === 'dragover') {
			setDragActive(true)
		} else if (e.type === 'dragleave') {
			setDragActive(false)
		}
	}

	const handleDrop = function (e: DragEvent<HTMLDivElement>) {
		e.preventDefault()
		e.stopPropagation()
		setDragActive(false)
		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			// at least one file has been dropped so do something
			// handleFiles(e.dataTransfer.files);
			console.log('debug: handleDrop: file: ', e.dataTransfer.files[0])
		}
	}

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (props.onChange) {
			props.onChange(props.id)
		}
		e.preventDefault()
		if (e.target.files && e.target.files[0]) {
			if (props.onChange) {
				props.onChange(props.id)
			}
		}
	}

	return (
		<div className='file' onDragEnter={handleDrag} style={props.style}>
			<input type='file' id='input-file-upload' multiple={false} onChange={handleChange} />
			<label id='label-file-upload' htmlFor='input-file-upload'>
				<div>
					<img src={props.src} alt={props.id} />
					<p>{props.children}</p>
					<p>Drag and drop your file here or</p>
					<button className='upload-button'>Upload a file</button>
				</div>
			</label>
			{dragActive && (
				<div
					id='drag-file-element'
					onDragEnter={handleDrag}
					onDragLeave={handleDrag}
					onDragOver={handleDrag}
					onDrop={handleDrop}
				></div>
			)}
		</div>
	)
}

export default File
