import React from 'react'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom'

import Root from './Root'
import Form from './components/form/Form'

import './App.css'

const router = createBrowserRouter(
  createRoutesFromElements([
    <Route path='/' element={<Root />} />,
    <Route path='/form' element={<Form />} />,
  ]),
)

function App() {
  return <RouterProvider router={router} />
}

export default App
