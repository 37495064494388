import { useEffect } from 'react'

import LabelAndTextInput from '../inputs/LabelAndTextInput'
import ButtonIconText from '@components/form/buttons/ButtonIconText'
import Title from '../title/Title'
import Pictogram from '@components/form/pictogram/Pictogram'
import person from '@assets/form/person.svg'
import deleteicon from '@assets/form/DeleteIcon.svg'
import corporation from '@assets/form/moral.svg'
import SelectBoxAndLabel from '../select/SelectBoxAndLabel'
import RadioGroupAndLabel from '../radio/RadioGroupAndLabel'
import { useAppDispatch, useAppSelector } from '@/hooks'
import {
	addPerson,
	setAddress,
	setBirthDate,
	setBirthPlace,
	setChildren,
	setContractDate,
	setContractPlace,
	setContractType,
	setEmail,
	setFirstName,
	setGender,
	setHasContract,
	setLastName,
	setMarriageCity,
	setMarriageDate,
	setMarried,
	setNationality,
	setPhone,
	setProfession,
} from '@components/form/sections/personSlice'
import {
	addCorporation,
	setCorporationAddress,
	setCorporationCapital,
	setCorporationDelegateAddress,
	setCorporationDelegateEmail,
	setCorporationDelegateFirstName,
	setCorporationDelegateGender,
	setCorporationDelegateLastName,
	setCorporationDelegatePhone,
	setCorporationName,
	setCorporationRegistar,
	setCorporationSiren,
} from '@components/form/sections/corporationSlice'
import type { RootState } from '@/store'

import './Section.css'

interface Section2Props {
	index: number
	onRemoveBuyer: (index: number) => void
	buyerType: string
	onSetBuyerType: (value: string) => void
}

function Section2(props: Section2Props) {
	const dispatch = useAppDispatch()

	const handleRemoveBuyer = () => {
		props.onRemoveBuyer(props.index)
	}

	const handleSetBuyerType = (value: string) => {
		props.onSetBuyerType(value)
	}

	useEffect(() => {
		dispatch(addPerson(props.index))
		dispatch(addCorporation(props.index))
	}, [dispatch, props.index])

	const getPersons = useAppSelector((state: RootState) => state.person.persons)

	return (
		<div className='section'>
			<div className='headerSection'>
				<Title>Vos coordonnées</Title>
				{getPersons.length > 1 && (
					<ButtonIconText
						className='supp'
						icon={deleteicon}
						text='Supprimer ce co-acquéreur'
						onClick={handleRemoveBuyer}
					/>
				)}
			</div>
			<div className='pictograms'>
				<Pictogram
					className={'picto1' + (props.buyerType === 'person' ? ' active' : '')}
					src={person}
					id={computeID(props.index, 'person')}
					onChange={() => handleSetBuyerType('person')}
					style={{ cursor: 'pointer' }}
				>
					Personne physique
				</Pictogram>
				<Pictogram
					className={'picto1' + (props.buyerType === 'corporation' ? ' active' : '')}
					src={corporation}
					id={computeID(props.index, 'corporation')}
					onChange={() => handleSetBuyerType('corporation')}
					style={{ cursor: 'pointer' }}
				>
					Personne morale
				</Pictogram>
			</div>
			{props.buyerType === 'person' && <Person index={props.index} />}
			{props.buyerType === 'corporation' && <Corporation index={props.index} />}
		</div>
	)
}

interface PersonProps {
	index: number
}

function computeID(index: number, name: string): string {
	return `${name}-${index}`
}

function Person(props: PersonProps) {
	const dispatch = useAppDispatch()

	const getGender = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.gender ?? '',
	)
	const onSetGender = (value: string) => {
		dispatch(setGender({ index: props.index, value }))
	}

	const getLastName = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.lastName ?? '',
	)
	const onSetLastName = (value: string) => {
		dispatch(setLastName({ index: props.index, value }))
	}

	const getFirstName = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.firstName ?? '',
	)
	const onSetFirstName = (value: string) => {
		dispatch(setFirstName({ index: props.index, value }))
	}

	const getPhone = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.phone ?? '',
	)
	const onSetPhone = (value: string) => {
		dispatch(setPhone({ index: props.index, value }))
	}

	const getEmail = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.email ?? '',
	)
	const onSetEmail = (value: string) => {
		dispatch(setEmail({ index: props.index, value }))
	}

	const getBirthDate = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.birthDate ?? '',
	)
	const onSetBirthDate = (value: string) => {
		dispatch(setBirthDate({ index: props.index, value }))
	}

	const getBirthPlace = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.birthPlace ?? '',
	)
	const onSetBirthPlace = (value: string) => {
		dispatch(setBirthPlace({ index: props.index, value }))
	}

	const getNationality = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.nationality ?? '',
	)
	const onSetNationality = (value: string) => {
		dispatch(setNationality({ index: props.index, value }))
	}

	const getProfession = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.profession ?? '',
	)
	const onSetProfession = (value: string) => {
		dispatch(setProfession({ index: props.index, value }))
	}

	const getAddress = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.address ?? '',
	)
	const onSetAddress = (value: string) => {
		dispatch(setAddress({ index: props.index, value }))
	}

	const getMarried = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.married ?? '',
	)
	const onSetMarried = (value: string) => {
		dispatch(setMarried({ index: props.index, value }))
	}

	const getChildren = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.children ?? '',
	)
	const onSetChildren = (value: string) => {
		dispatch(setChildren({ index: props.index, value }))
	}

	const getHasContract = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.hasContract ?? '',
	)
	const onSetHasContract = (value: string) => {
		dispatch(setHasContract({ index: props.index, value }))
	}

	return (
		<div>
			<div className='formContent'>
				<div className='fieldLine'>
					<SelectBoxAndLabel
						id={computeID(props.index, 'personGender')}
						text='Civilité'
						options={{ '': 'Sélectionner', mr: 'M', mrs: 'Mme' }}
						required={{ required: true }}
						onChange={onSetGender}
						value={getGender}
					/>
					<LabelAndTextInput
						id={computeID(props.index, 'personLastname')}
						text='Nom'
						placeholder='Nom'
						value={getLastName}
						required={{ required: true }}
						onChange={onSetLastName}
					/>
					<LabelAndTextInput
						id={computeID(props.index, 'personFirstname')}
						text='Prénom'
						placeholder='Prénom'
						value={getFirstName}
						required={{ required: true }}
						onChange={onSetFirstName}
					/>
				</div>
				<div className='fieldLine'>
					<LabelAndTextInput
						id={computeID(props.index, 'personPhone')}
						text='Téléphone'
						placeholder='06xxxxxxxx'
						value={getPhone}
						required={{ required: true }}
						onChange={onSetPhone}
					/>
					<LabelAndTextInput
						id={computeID(props.index, 'personEmail')}
						text='Adresse mail'
						placeholder='Mail'
						value={getEmail}
						required={{ required: true }}
						onChange={onSetEmail}
					/>
				</div>
				<div className='fieldLine'>
					<LabelAndTextInput
						id={computeID(props.index, 'personBirthDate')}
						text='Date de naissance'
						placeholder='jj/mm/aaaa'
						value={getBirthDate}
						required={{ required: true }}
						onChange={onSetBirthDate}
					/>
					<LabelAndTextInput
						id={computeID(props.index, 'personBirthPlace')}
						text='Lieu de naissance'
						placeholder='Paris'
						value={getBirthPlace}
						required={{ required: true }}
						onChange={onSetBirthPlace}
					/>
					<LabelAndTextInput
						id={computeID(props.index, 'personNationality')}
						text='Nationalité'
						placeholder='Française'
						value={getNationality}
						required={{ required: true }}
						onChange={onSetNationality}
					/>
				</div>
				<div className='fieldLine'>
					<LabelAndTextInput
						id={computeID(props.index, 'personProfession')}
						text='Profession'
						placeholder='Profession'
						value={getProfession}
						required={{ required: true }}
						onChange={onSetProfession}
					/>
					<LabelAndTextInput
						id={computeID(props.index, 'personAddress')}
						text='Adresse'
						placeholder='Adresse'
						value={getAddress}
						required={{ required: true }}
						onChange={onSetAddress}
					/>
				</div>
				<div className='fieldLine'>
					<SelectBoxAndLabel
						id={computeID(props.index, 'personMarried')}
						text='Status marital'
						options={{
							'': 'Sélectionner',
							single: 'Célibataire',
							married: 'Marié',
							civilUnion: 'Pacsé',
							divorced: 'Divorcé',
							widowhood: 'Veuf',
						}}
						value={getMarried}
						required={{ required: true }}
						onChange={onSetMarried}
					/>
					<LabelAndTextInput
						id={computeID(props.index, 'personChildren')}
						text='Nombre d’enfants'
						placeholder='0'
						value={getChildren}
						required={{ required: true }}
						onChange={onSetChildren}
					/>
				</div>
				{(getMarried === 'married' || getMarried === 'civilUnion') && (
					<Marriage index={props.index} />
				)}
				<div className='fieldLine'>
					<RadioGroupAndLabel
						id={computeID(props.index, 'personHasContract')}
						text='Avez-vous un contrat ?'
						options={{ no: 'Non', yes: 'Oui' }}
						defaultChecked={getHasContract}
						required={{ required: true }}
						onChange={onSetHasContract}
					/>
				</div>
			</div>
			{getHasContract === 'yes' && <Contract index={props.index} />}
		</div>
	)
}

interface MarriageProps {
	index: number
}

function Marriage(props: MarriageProps) {
	const dispatch = useAppDispatch()

	const getMarriageCity = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.marriageCity ?? '',
	)
	const onSetMarriageCity = (value: string) => {
		dispatch(setMarriageCity({ index: props.index, value }))
	}

	const getMarriageDate = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.marriageDate ?? '',
	)
	const onSetMarriageDate = (value: string) => {
		dispatch(setMarriageDate({ index: props.index, value }))
	}

	return (
		<div
			className='formContent'
			style={{
				margin: '0',
			}}
		>
			<div className='fieldLine'>
				<LabelAndTextInput
					id={computeID(props.index, 'personMarriageCity')}
					text='Ville du mariage / PACS'
					placeholder='Ville'
					value={getMarriageCity}
					required={{ required: true }}
					onChange={onSetMarriageCity}
				/>
				<LabelAndTextInput
					id={computeID(props.index, 'personMarriageDate')}
					text='Date du mariage / PACS'
					placeholder='jj/mm/aaaa'
					value={getMarriageDate}
					required={{ required: true }}
					onChange={onSetMarriageDate}
				/>
			</div>
		</div>
	)
}

interface ContractProps {
	index: number
}

function Contract(props: ContractProps) {
	const dispatch = useAppDispatch()

	const getContractDate = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.contractDate ?? '',
	)
	const onSetContractDate = (value: string) => {
		dispatch(setContractDate({ index: props.index, value }))
	}

	const getContractPlace = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.contractPlace ?? '',
	)
	const onSetContractPlace = (value: string) => {
		dispatch(setContractPlace({ index: props.index, value }))
	}

	const getContractType = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.contractType ?? '',
	)
	const onSetContractType = (value: string) => {
		dispatch(setContractType({ index: props.index, value }))
	}

	return (
		<div className='formContent'>
			<div className='fieldLine'>
				<LabelAndTextInput
					id={computeID(props.index, 'contractDate')}
					text='Date du contrat'
					placeholder='jj/mm/aaaa'
					value={getContractDate}
					required={{ required: true }}
					onChange={onSetContractDate}
				/>
				<LabelAndTextInput
					id={computeID(props.index, 'contractPlace')}
					text='Lieu de signature'
					placeholder='Ville'
					value={getContractPlace}
					required={{ required: true }}
					onChange={onSetContractPlace}
				/>
				<SelectBoxAndLabel
					id={computeID(props.index, 'contractType')}
					text='Régime'
					options={{
						'': 'Sélectionner',
						universalCommunity: 'Communauté universelle',
						separated: 'Séparation de biens',
						participation: 'Participation aux acquêts',
						acquittalsCommunity: 'Communauté réduite aux acquêts',
					}}
					value={getContractType}
					required={{ required: true }}
					onChange={onSetContractType}
				/>
			</div>
		</div>
	)
}

interface CorporationProps {
	index: number
}

function Corporation(props: CorporationProps) {
	const dispatch = useAppDispatch()

	const getCorporationName = useAppSelector(
		(state: RootState) => state.corporation.corporations[props.index].corporationName,
	)
	const onSetCorporationName = (value: string) => {
		dispatch(setCorporationName({ index: props.index, value }))
	}

	const getCorporationCapital = useAppSelector(
		(state: RootState) => state.corporation.corporations[props.index].corporationCapital,
	)
	const onSetCorporationCapital = (value: string) => {
		dispatch(setCorporationCapital({ index: props.index, value }))
	}

	const getCorporationAddress = useAppSelector(
		(state: RootState) => state.corporation.corporations[props.index].corporationAddress,
	)
	const onSetCorporationAddress = (value: string) => {
		dispatch(setCorporationAddress({ index: props.index, value }))
	}

	const getCorporationSiren = useAppSelector(
		(state: RootState) => state.corporation.corporations[props.index].corporationSiren,
	)
	const onSetCorporationSiren = (value: string) => {
		dispatch(setCorporationSiren({ index: props.index, value }))
	}

	const getCorporationRegistar = useAppSelector(
		(state: RootState) => state.corporation.corporations[props.index].corporationRegistar,
	)
	const onSetCorporationRegistar = (value: string) => {
		dispatch(setCorporationRegistar({ index: props.index, value }))
	}

	const getCorporationDelegateGender = useAppSelector(
		(state: RootState) => state.corporation.corporations[props.index].corporationDelegateGender,
	)
	const onSetCorporationDelegateGender = (value: string) => {
		dispatch(setCorporationDelegateGender({ index: props.index, value }))
	}

	const getCorporationDelegateLastName = useAppSelector(
		(state: RootState) => state.corporation.corporations[props.index].corporationDelegateLastName,
	)
	const onSetCorporationDelegateLastName = (value: string) => {
		dispatch(setCorporationDelegateLastName({ index: props.index, value }))
	}

	const getCorporationDelegateFirstName = useAppSelector(
		(state: RootState) => state.corporation.corporations[props.index].corporationDelegateFirstName,
	)
	const onSetCorporationDelegateFirstName = (value: string) => {
		dispatch(setCorporationDelegateFirstName({ index: props.index, value }))
	}

	const getCorporationDelegatePhone = useAppSelector(
		(state: RootState) => state.corporation.corporations[props.index].corporationDelegatePhone,
	)
	const onSetCorporationDelegatePhone = (value: string) => {
		dispatch(setCorporationDelegatePhone({ index: props.index, value }))
	}

	const getCorporationDelegateEmail = useAppSelector(
		(state: RootState) => state.corporation.corporations[props.index].corporationDelegateEmail,
	)
	const onSetCorporationDelegateEmail = (value: string) => {
		dispatch(setCorporationDelegateEmail({ index: props.index, value }))
	}

	const getCorporationDelegateAddress = useAppSelector(
		(state: RootState) => state.corporation.corporations[props.index].corporationDelegateAddress,
	)
	const onSetCorporationDelegateAddress = (value: string) => {
		dispatch(setCorporationDelegateAddress({ index: props.index, value }))
	}

	return (
		<div className='formContent'>
			<div className='fieldLine'>
				<LabelAndTextInput
					id={computeID(props.index, 'corporationName')}
					text='Raison sociale'
					placeholder='Nom'
					value={getCorporationName}
					required={{ required: true }}
					onChange={onSetCorporationName}
				/>
				<LabelAndTextInput
					id={computeID(props.index, 'corporationCapital')}
					text='Capital social'
					placeholder='Montant'
					value={getCorporationCapital}
					required={{ required: true }}
					onChange={onSetCorporationCapital}
				/>
			</div>
			<div className='fieldLine'>
				<LabelAndTextInput
					id={computeID(props.index, 'corporationAddress')}
					text='Adresse de la société'
					placeholder='Adresse'
					value={getCorporationAddress}
					required={{ required: true }}
					onChange={onSetCorporationAddress}
				/>
			</div>
			<div className='fieldLine'>
				<LabelAndTextInput
					id={computeID(props.index, 'corporationSiren')}
					text='SIREN'
					placeholder='Numéro'
					value={getCorporationSiren}
					required={{ required: true }}
					onChange={onSetCorporationSiren}
				/>
				<LabelAndTextInput
					id={computeID(props.index, 'corporationRegistar')}
					text='Registre du commerce et des sociétés'
					placeholder='Ville'
					value={getCorporationRegistar}
					required={{ required: true }}
					onChange={onSetCorporationRegistar}
				/>
			</div>
			<div className='fieldLine'>
				<SelectBoxAndLabel
					id={computeID(props.index, 'corporationDelegateGender')}
					text='Civilité'
					options={{ '': 'Sélectionner', mr: 'M', mrs: 'Mme' }}
					value={getCorporationDelegateGender}
					required={{ required: true }}
					onChange={onSetCorporationDelegateGender}
				/>
				<LabelAndTextInput
					id={computeID(props.index, 'corporationDelegateLastname')}
					text='Nom'
					placeholder='Texte'
					value={getCorporationDelegateLastName}
					required={{ required: true }}
					onChange={onSetCorporationDelegateLastName}
				/>
				<LabelAndTextInput
					id={computeID(props.index, 'corporationDelegateFirstname')}
					text='Prénom'
					placeholder='Texte'
					value={getCorporationDelegateFirstName}
					required={{ required: true }}
					onChange={onSetCorporationDelegateFirstName}
				/>
			</div>
			<div className='fieldLine'>
				<LabelAndTextInput
					id={computeID(props.index, 'corporationDelegatePhone')}
					text='Téléphone'
					placeholder='06xxxxxxxx'
					value={getCorporationDelegatePhone}
					required={{ required: true }}
					onChange={onSetCorporationDelegatePhone}
				/>
				<LabelAndTextInput
					id={computeID(props.index, 'corporationDelegateEmail')}
					text='Adresse mail'
					placeholder='Mail'
					value={getCorporationDelegateEmail}
					required={{ required: true }}
					onChange={onSetCorporationDelegateEmail}
				/>
			</div>
			<div className='fieldLine'>
				<LabelAndTextInput
					id={computeID(props.index, 'corporationDelegateAddress')}
					text='Adresse'
					placeholder='Adresse'
					value={getCorporationDelegateAddress}
					required={{ required: true }}
					onChange={onSetCorporationDelegateAddress}
				/>
			</div>
		</div>
	)
}

export default Section2
