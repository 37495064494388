import { createSlice } from '@reduxjs/toolkit'

type NotaryState = {
  name: string
  city: string
}

const initialState: NotaryState = {
  name: '',
  city: '',
}

export const notarySlice = createSlice({
  name: 'Notary',
  initialState,
  reducers: {
    setName: (state, action) => {
      state.name = action.payload
    },
    setCity: (state, action) => {
      state.city = action.payload
    },
  },
})

export const { setName, setCity } = notarySlice.actions

export default notarySlice.reducer
