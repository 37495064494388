import React from 'react'

import './Label.css'

interface LabelProps {
  htmlFor: string
  children: React.ReactNode
}

function Label(props: LabelProps) {
  return <label htmlFor={props.htmlFor}>{props.children}</label>
}

export default Label
