import React from 'react'
import { setTermsAndConditions } from '@components/form/sections/termsSlice'
import { useAppDispatch, useAppSelector } from '@/hooks'
import type { RootState } from '@/store'

import CheckAndLabel from '../check/CheckAndLabel'

function Terms() {
	const dispatch = useAppDispatch()

	const getTerms = useAppSelector((state: RootState) => state.terms.termsAndConditions)
	const onSetTerms = () => {
		console.log('Terms: ', getTerms)
		dispatch(setTermsAndConditions(!getTerms))
	}

	return (
		<div>
			<CheckAndLabel
				id='termsAndConditions'
				name='termsAndConditions'
				text="J'ai lu et j'accepte les conditions générales de vente"
				required={{ required: true }}
				onChange={onSetTerms}
			/>
		</div>
	)
}

export default Terms
