import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import './Root.css'

function Root() {
  const [password, setPassword] = useState('')
  const navigate = useNavigate()

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  const handleSubmit = () => {
    console.log('password: ' + process.env.REACT_APP_ACCESS_PASSWORD)
    if (password === process.env.REACT_APP_ACCESS_PASSWORD) {
      navigate('/form')
    }
  }

  return (
    <main>
      <p>
        <label htmlFor='password'>Password:</label>
        <input id='password' name='password' type='password' onChange={handleChangePassword} />
      </p>
      <p id='submit' onClick={handleSubmit}>
        Enter
      </p>
    </main>
  )
}

export default Root
