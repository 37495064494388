import React, { useState } from 'react'
import { useAppSelector } from '@/hooks'

import Section1 from './sections/Section1'
import Section2 from './sections/Section2'
import Funding from './sections/Funding'
import Notary from './sections/Notary'
import Files from './sections/Files'
import Terms from './sections/Terms'
import ButtonIconText from './buttons/ButtonIconText'
import { useAppDispatch } from '@/hooks'
import { removePerson } from '@components/form/sections/personSlice'
import { removeCorporation } from '@components/form/sections/corporationSlice'
import arrow from '@assets/form/arrow.svg'
import ajouter from '@assets/form/ajouter.svg'
import type { RootState } from '@/store'

import './Form.css'

function Form() {
	const [buyers, setBuyers] = useState([Section2])
	const [buyerType, setBuyerType] = useState('')
	const [fundingType, setFundingType] = useState('')
	const [fileKbis, setFileKbis] = useState('')
	const [fileStatus, setFileStatus] = useState('')

	const dispatch = useAppDispatch()

	// **** getters ****
	const persons = useAppSelector((state: RootState) => state.person.persons)
	const corporations = useAppSelector((state: RootState) => state.corporation.corporations)
	const funding = useAppSelector((state: RootState) => state.funding)
	const notary = useAppSelector((state: RootState) => state.notary)

	const handleAddBuyer = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault()
		setBuyers([...buyers, Section2])
	}

	const handleSetBuyerType = (value: string) => {
		setBuyerType(value)
	}

	const handleSetFundingType = (value: string) => {
		setFundingType(value)
	}

	const handleSetFileKbis = (path: string) => {
		setFileKbis(path)
	}

	const handleSetFileStatus = (path: string) => {
		setFileStatus(path)
	}

	const handleRemoveBuyer = (index: number) => {
		setBuyers(buyers.filter((_, i) => i !== index))
		dispatch(removePerson(index))
		dispatch(removeCorporation(index))
	}

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		e.currentTarget.checkValidity()
		console.log(persons)
		console.log(corporations)
		console.log(funding)
		console.log(notary)
	}

	return (
		<form id='form' onSubmit={handleSubmit}>
			<div className='header'>
				<p id='logo'></p>
				<h1 id='program'>Nom du programme</h1>
				<h2 id='city'>Ville</h2>
			</div>
			<Section1 />
			{buyers.map((Buyer, index) => (
				<Buyer
					key={index}
					index={index}
					onRemoveBuyer={handleRemoveBuyer}
					buyerType={buyerType}
					onSetBuyerType={handleSetBuyerType}
				/>
			))}
			<ButtonIconText icon={ajouter} text='Ajouter un co-acquéreur' onClick={handleAddBuyer} />
			<Funding fundingType={fundingType} onSetFundingType={handleSetFundingType} />
			{funding.hasNotary === 'yes' && <Notary />}
			<Files
				fileKbis={fileKbis}
				onSetFileKbis={handleSetFileKbis}
				fileStatus={fileStatus}
				onSetFileStatus={handleSetFileStatus}
			/>
			<Terms />
			<ButtonIconText
				className='send'
				invertIcon={true}
				text='Envoyer'
				icon={arrow}
				onClick={() => {}}
			/>
		</form>
	)
}

export default Form
