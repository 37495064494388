import React from 'react'

import Button from './Button'
import IconAndText from './IconAndText'

import './ButtonIconText.css'

interface ButtonIconTextProps {
	className?: string
	icon: string
	text: string
	invertIcon?: boolean
	onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

function ButtonIconText(props: ButtonIconTextProps) {
	return (
		<div className={props.className ? props.className + ' buttonIconText' : 'buttonIconText'}>
			<Button onClick={props.onClick}>
				<IconAndText
					className={props.className}
					icon={props.icon}
					invertIcon={props.invertIcon}
					text={props.text}
				/>
			</Button>
		</div>
	)
}

export default ButtonIconText
