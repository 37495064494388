import Label from '../labels/Label'

import './SelectBoxAndLabel.css'

interface SelectBoxAndLabelProps {
  id: string
  text: string
  options: { [key: string]: string }
  required?: Object
  onChange: (value: string) => void
  value?: string
  style?: React.CSSProperties
}

function SelectBoxAndLabel(props: SelectBoxAndLabelProps) {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    props.onChange(e.target.value)
  }

  return (
    <div style={props.style} className='SelectBoxAndLabel'>
      <Label htmlFor={props.id}>{props.text}</Label>
      <select id={props.id} value={props.value} {...props.required} onChange={handleChange}>
        {Object.keys(props.options).map(key => {
          return (
            <option key={key} value={key}>
              {props.options[key]}
            </option>
          )
        })}
      </select>
    </div>
  )
}

export default SelectBoxAndLabel
