import Label from '../labels/Label'

import './RadioAndLabel.css'

interface RadioAndLabelProps {
  id: string
  name: string
  text: string
  defaultChecked?: string
  required?: Object
  onChange: (value: string) => void
}

function RadioAndLabel(props: RadioAndLabelProps) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.target.value)
  }

  return (
    <div className='RadioAndLabel'>
      <input
        type='radio'
        id={props.id}
        name={props.name}
        value={props.id}
        defaultChecked={props.id === props.defaultChecked}
        {...props.required}
        onChange={handleChange}
      />
      <Label htmlFor={props.id}>{props.text}</Label>
    </div>
  )
}

export default RadioAndLabel
