import LabelAndTextInput from '../inputs/LabelAndTextInput'
import Title from '../title/Title'
import { setCity, setName } from '@components/form/sections/notarySlice'
import { useAppDispatch, useAppSelector } from '@/hooks'
import type { RootState } from '@/store'

import './Section.css'

function Notary() {
	const dispatch = useAppDispatch()

	const getName = useAppSelector((state: RootState) => state.notary.name)
	const onSetName = (value: string) => {
		dispatch(setName(value))
	}

	const getCity = useAppSelector((state: RootState) => state.notary.city)
	const onSetCity = (value: string) => {
		dispatch(setCity(value))
	}

	return (
		<div className='section'>
			<Title>Votre notaire</Title>
			<div className='formContent'>
				<div className='fieldLine'>
					<LabelAndTextInput
						id='notaryName'
						text='Nom du notaire'
						placeholder='Jean Dupont'
						value={getName}
						required={{ required: true }}
						onChange={onSetName}
					/>
					<LabelAndTextInput
						id='notaryCity'
						text='Ville du notaire'
						placeholder='Paris'
						value={getCity}
						required={{ required: true }}
						onChange={onSetCity}
					/>
				</div>
			</div>
		</div>
	)
}

export default Notary
