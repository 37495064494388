import Title from '../title/Title'
import File from '../files/File'
import add from '@assets/form/plus.svg'

import './Section.css'

interface FilesProps {
	fileKbis: string
	onSetFileKbis: (path: string) => void
	fileStatus: string
	onSetFileStatus: (path: string) => void
}

function Files(props: FilesProps) {
	const handleSetKbis = (path: string) => {
		props.onSetFileKbis(path)
	}

	const handleSetStatus = (path: string) => {
		props.onSetFileStatus(path)
	}

	return (
		<div className='section'>
			<Title>Vos documents</Title>
			<div className='pictograms'>
				<File
					src={add}
					id='kbis'
					onChange={path => handleSetKbis(path)}
					style={{ cursor: 'pointer' }}
				/>
				<File
					src={add}
					id='status'
					onChange={path => handleSetStatus(path)}
					style={{ cursor: 'pointer' }}
				/>
			</div>
		</div>
	)
}

export default Files
